import {
    CONSENT_PAGE_SAVE_ALL_HANDLER
} from "./actions";

export const consentReducer = (state = [], action) => {
    switch (action.type) {

        case CONSENT_PAGE_SAVE_ALL_HANDLER: {
            return {
                ...state,
                data: {
                    ...state.data,
                    consents: [...action.payload]
                }
            }
        }
        
        default: {
            return {
                ...state
            };
        }
    }
}