import React from 'react';

export default class PopupText extends React.Component {
  render() {
    const { data } = this.props;

    return (
          <div dangerouslySetInnerHTML={{ __html: data }} />
          )
  }
}
