import React, { Component } from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import ReactSelectValidator from "../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";
import { convertToRaw, convertFromRaw } from 'draft-js'
import { convertToHTML, convertFromHTML } from 'draft-convert';
import { MUIEditorState, toolbarControlTypes } from 'react-mui-draft-wysiwyg'
import Settings from "./Settings/settings";
import PopupText from "./Settings/popup";
import MainContent from "./MainContent/container";
import PopupContent from "./PopupContent/container";

class CreateTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            showModal: false,
            editorState: MUIEditorState.createEmpty(),
            contentEditorState: MUIEditorState.createEmpty()
        }
    }

    showCookiePopupHandler = () => {
        this.setState({ showPopup: !this.state.showPopup })
    }

    showCookieModal = () => {
        this.setState({ showModal: !this.state.showModal })
    }
    SubmitHandler = () => {
        this.props.submitFormHandler()
    }

    submitUpdateHandler = () => {
        this.props.submitUpdateHandler()
    }

    onChange = (newState) => {
        this.setState({
            editorState: newState
        })
        let currentContentAsHTML = convertToHTML(this.state.editorState.getCurrentContent());
        this.props.saveEditorTextHandler(currentContentAsHTML)
    }

    onMainContentChange = (newState) => {
        this.setState({
            contentEditorState: newState
        })
        let currentContentAsHTML = convertToHTML(this.state.contentEditorState.getCurrentContent());
        this.props.saveContentEditorTextHandler(currentContentAsHTML)
    }

    componentDidMount = async () => {
        await this.props.fetchDataIfNeeded()
        let convertedData = convertFromHTML(this.props.fields.PopupDiscription.value);
        let convertedRawData = convertToRaw(convertedData);
        const rawContents = {
            blocks: [...convertedRawData.blocks],
            entityMap: {},
        }
        this.setState({
            editorState: MUIEditorState.createWithContent(
                {},
                convertFromRaw(rawContents),
              )
        })
        let currentContentAsHTML = convertToHTML(this.state.editorState.getCurrentContent());
        this.props.saveEditorTextHandler(currentContentAsHTML);

        //main content
        let contentData = convertFromHTML(this.props.fields.MainContent.value);
        let contentRawData = convertToRaw(contentData);
        const rawMainContents = {
            blocks: [...contentRawData.blocks],
            entityMap: {},
        }
        this.setState({
            contentEditorState: MUIEditorState.createWithContent(
                {},
                convertFromRaw(rawMainContents),
              )
        })
        let currentMainContentAsHTML = convertToHTML(this.state.contentEditorState.getCurrentContent());
        this.props.saveContentEditorTextHandler(currentMainContentAsHTML);
    }

    render() {
        const {
            classes,
            fields,
            textFieldChangeHandler,
            selectFieldChangeHandler,
            isUpdate
        } = this.props;

        return (
            <div className={classes.polygonbg}>
                <Grid container className={classes.applicationWrapper} justify="center">
                    <Grid item xs={12} sm={12}>

                        <h1 className="heading">{isUpdate ? "Update" : "Create"} </h1>
                        <ValidatorForm
                            onSubmit={isUpdate ? this.submitUpdateHandler : this.SubmitHandler}
                            autoComplete="off"
                        >

                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <label className={classes.labels}>{fields.Title.label}</label>
                                    <CustomTextValidator
                                        id={fields.Title.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="text"
                                        placeholder={fields.Title.placeholder}
                                        name={fields.Title.name}
                                        value={fields.Title.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.Title.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.fields}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} md={3}>
                                    <label className={classes.labels}>{fields.TitleBgColor.label}</label>
                                    <CustomTextValidator
                                        id={fields.TitleBgColor.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="color"
                                        placeholder={fields.TitleBgColor.placeholder}
                                        name={fields.TitleBgColor.name}
                                        value={fields.TitleBgColor.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.TitleBgColor.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.colorFields}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <label className={classes.labels}>{fields.TitleFontColor.label}</label>
                                    <CustomTextValidator
                                        id={fields.TitleFontColor.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="color"
                                        placeholder={fields.TitleFontColor.placeholder}
                                        name={fields.TitleFontColor.name}
                                        value={fields.TitleFontColor.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.TitleFontColor.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.colorFields}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} md={6}>
                                    <label className={classes.labels}>{fields.Application.label}</label>
                                    <ReactSelectValidator
                                        id={fields.Application.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="text"
                                        placeholder={fields.Application.placeholder}
                                        name={fields.Application.name}
                                        value={fields.Application.value}
                                        onChange={value => {
                                            selectFieldChangeHandler(
                                                fields.Application.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        isSearchable={true}
                                        options={fields.Application.options}
                                        className={classes.fields}
                                    />
                                </Grid>
                                

                                <Grid item xs={12} md={6}>
                                    <label className={classes.labels}>{fields.Categories.label}</label>
                                    <ReactSelectValidator
                                        id={fields.Categories.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="text"
                                        placeholder={fields.Categories.placeholder}
                                        name={fields.Categories.name}
                                        value={fields.Categories.value}
                                        onChange={value => {
                                            selectFieldChangeHandler(
                                                fields.Categories.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        isSearchable={true}
                                        options={fields.Categories.options}
                                        isMulti
                                        className={classes.fields}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <label className={classes.labels}>{fields.AllowBtnText.label}</label>
                                    <CustomTextValidator
                                        id={fields.AllowBtnText.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="text"
                                        placeholder={fields.AllowBtnText.placeholder}
                                        name={fields.AllowBtnText.name}
                                        value={fields.AllowBtnText.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.AllowBtnText.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.fields}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <label className={classes.labels}>{fields.AllowBtnBgColor.label}</label>
                                    <CustomTextValidator
                                        id={fields.AllowBtnBgColor.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="color"
                                        placeholder={fields.AllowBtnBgColor.placeholder}
                                        name={fields.AllowBtnBgColor.name}
                                        value={fields.AllowBtnBgColor.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.AllowBtnBgColor.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.colorFields}
                                    />
                                </Grid>


                                <Grid item xs={12} md={3}>
                                    <label className={classes.labels}>{fields.AllowBtnFontColor.label}</label>
                                    <CustomTextValidator
                                        id={fields.AllowBtnFontColor.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="color"
                                        placeholder={fields.AllowBtnFontColor.placeholder}
                                        name={fields.AllowBtnFontColor.name}
                                        value={fields.AllowBtnFontColor.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.AllowBtnFontColor.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.colorFields}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <label className={classes.labels}>{fields.CancelBtnText.label}</label>
                                    <CustomTextValidator
                                        id={fields.CancelBtnText.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="text"
                                        placeholder={fields.CancelBtnText.placeholder}
                                        name={fields.CancelBtnText.name}
                                        value={fields.CancelBtnText.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.CancelBtnText.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.fields}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <label className={classes.labels}>{fields.CancelBtnBgColor.label}</label>
                                    <CustomTextValidator
                                        id={fields.CancelBtnBgColor.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="color"
                                        placeholder={fields.CancelBtnBgColor.placeholder}
                                        name={fields.CancelBtnBgColor.name}
                                        value={fields.CancelBtnBgColor.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.CancelBtnBgColor.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.colorFields}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <label className={classes.labels}>{fields.CancelBtnColor.label}</label>
                                    <CustomTextValidator
                                        id={fields.CancelBtnColor.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="color"
                                        placeholder={fields.CancelBtnColor.placeholder}
                                        name={fields.CancelBtnColor.name}
                                        value={fields.CancelBtnColor.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.CancelBtnColor.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.colorFields}
                                    />
                                </Grid>
                                {/* maincontent editor */}
                                <Grid item xs={9}>
                                    {/* <label className={classes.labels}>{fields.MainContent.label}</label>
                                    <div className={classes.texteditorwrapper}>
                                        <MUIEditor editorState={this.state.contentEditorState} 
                                            onChange={this.onMainContentChange}
                                            config={config}
                                        />
                                    </div> */}
                                    <MainContent />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                        <label className={classes.labels}>{fields.MainContentBgColor.label}</label>
                                        <CustomTextValidator
                                            id={fields.MainContentBgColor.name}
                                            validations={true}
                                            fullWidth={true}
                                            type="color"
                                            placeholder={fields.MainContentBgColor.placeholder}
                                            name={fields.MainContentBgColor.name}
                                            value={fields.MainContentBgColor.value}
                                            onChange={value => {
                                                textFieldChangeHandler(
                                                    fields.MainContentBgColor.name,
                                                    value
                                                );
                                            }}
                                            validators={["required"]}
                                            errorMessages={["Required"]}
                                            className={classes.colorFields}
                                        />
                                        </Grid>
                                        <Grid item xs={12}>
                                        <label className={classes.labels}>{fields.MainContentFontColor.label}</label>
                                        <CustomTextValidator
                                            id={fields.MainContentFontColor.name}
                                            validations={true}
                                            fullWidth={true}
                                            type="color"
                                            placeholder={fields.MainContentFontColor.placeholder}
                                            name={fields.MainContentFontColor.name}
                                            value={fields.MainContentFontColor.value}
                                            onChange={value => {
                                                textFieldChangeHandler(
                                                    fields.MainContentFontColor.name,
                                                    value
                                                );
                                            }}
                                            validators={["required"]}
                                            errorMessages={["Required"]}
                                            className={classes.colorFields}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <label className={classes.labels}>{fields.GoogleAnalyticsId.label}</label>
                                    <CustomTextValidator
                                        id={fields.GoogleAnalyticsId.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="text"
                                        placeholder={fields.GoogleAnalyticsId.placeholder}
                                        name={fields.GoogleAnalyticsId.name}
                                        value={fields.GoogleAnalyticsId.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.GoogleAnalyticsId.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.colorFields}
                                    />
                                </Grid>

                                <p className={classes.popuptitle}>Cookie Popup</p>

                                <Grid item xs={12} md={6}>
                                    <label className={classes.labels}>{fields.PopupHeaderText.label}</label>
                                    <CustomTextValidator
                                        id={fields.PopupHeaderText.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="text"
                                        placeholder={fields.PopupHeaderText.placeholder}
                                        name={fields.PopupHeaderText.name}
                                        value={fields.PopupHeaderText.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.PopupHeaderText.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.fields}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <label className={classes.labels}>{fields.PopupBgColor.label}</label>
                                    <CustomTextValidator
                                        id={fields.PopupBgColor.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="color"
                                        placeholder={fields.PopupBgColor.placeholder}
                                        name={fields.PopupBgColor.name}
                                        value={fields.PopupBgColor.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.PopupBgColor.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.colorFields}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <label className={classes.labels}>{fields.PopupFontColor.label}</label>
                                    <CustomTextValidator
                                        id={fields.PopupFontColor.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="color"
                                        placeholder={fields.PopupFontColor.placeholder}
                                        name={fields.PopupFontColor.name}
                                        value={fields.PopupFontColor.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.PopupFontColor.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.colorFields}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <label className={classes.labels}>{fields.PopupBtnConfirm.label}</label>
                                    <CustomTextValidator
                                        id={fields.PopupBtnConfirm.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="text"
                                        placeholder={fields.PopupBtnConfirm.placeholder}
                                        name={fields.PopupBtnConfirm.name}
                                        value={fields.PopupBtnConfirm.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.PopupBtnConfirm.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.fields}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <label className={classes.labels}>{fields.PopupBtnConfirmBgColor.label}</label>
                                    <CustomTextValidator
                                        id={fields.PopupBtnConfirmBgColor.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="color"
                                        placeholder={fields.PopupBtnConfirmBgColor.placeholder}
                                        name={fields.PopupBtnConfirmBgColor.name}
                                        value={fields.PopupBtnConfirmBgColor.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.PopupBtnConfirmBgColor.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.colorFields}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <label className={classes.labels}>{fields.PopupBtnConfirmFontColor.label}</label>
                                    <CustomTextValidator
                                        id={fields.PopupBtnConfirmFontColor.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="color"
                                        placeholder={fields.PopupBtnConfirmFontColor.placeholder}
                                        name={fields.PopupBtnConfirmFontColor.name}
                                        value={fields.PopupBtnConfirmFontColor.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.PopupBtnConfirmFontColor.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.colorFields}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    {/* <label className={classes.labels}>{fields.PopupDiscription.label}</label>
                                    <div className={classes.texteditorwrapper}>
                                        <MUIEditor editorState={this.state.editorState} 
                                            onChange={this.onChange}
                                            config={config}
                                        />
                                    </div> */}
                                    <PopupContent />
                                </Grid>

                            </Grid>
                            
                            <Button variant="contained" color="primary" type="submit" className={classes.submitBtn} >
                                {isUpdate ? "Update" : "Add"}
                            </Button>
                            <div className={classes.passwordOptions}>

                            </div>
                        </ValidatorForm>
                    </Grid>

                </Grid>
                
                {/* default template */}
                <Button onClick={this.showCookiePopupHandler} variant="contained" color="primary" className={classes.showcookie} >
                    Try Now
                </Button>
                { this.state.showPopup && <div className={classes.cookiecontent}>
                    <div className="cookiewrapper" style={{background: fields.MainContentBgColor.value, color: fields.MainContentFontColor.value}}>
                        <p style={{color: fields.MainContentFontColor.value}}><PopupText data={fields.MainContent.value}/></p>
                        <div className="cookieoptionbtns">
                            <button onClick={this.showCookieModal} className="first" style={{background: fields.CancelBtnBgColor.value, color: fields.CancelBtnColor.value}}>{fields.CancelBtnText.value}</button>
                            <button onClick={this.showCookiePopupHandler} className="second" style={{background: fields.AllowBtnBgColor.value, color: fields.AllowBtnFontColor.value}}>{fields.AllowBtnText.value}</button>
                        </div>
                    </div>
                </div> }

                {
                    this.state.showModal ? 
                    <Settings
                        categories={fields.Categories.value}
                        content={fields.PopupDiscription.value}
                        title={fields.PopupHeaderText.value}
                        confirmText={fields.PopupBtnConfirm.value}
                        btnBGColor={fields.PopupBtnConfirmBgColor.value}
                        btnFontColor={fields.PopupBtnConfirmFontColor.value}
                    /> : ""
                }
                
            </div>
        )
    }
}

export default withStyles(styles)(CreateTemplate);