const styles = theme => ({
    downloadwrapper: {
        minHeight: "calc(100vh - 116px)",
        "& .fileinstructions": {
            fontSize: 17,
            "& span": {
                display: "inline-block",
                background: "#e5e5e8",
                padding: 13,
                width: "auto",
                marginTop: 17,
                marginBottom: 13,
            
            }
        }
    },
    title: {
        display: "block",
        width: "100%",
    },
    guidlineswrapper: {
        display: "block",
        "& .title": {
            display: "block",
            marginTop: 32,
            fontSize: 16,
            fontWeight: 600,
        },
        "& p": {
            marginTop: 13,
            "& span": {
                fontWeight: 600,
            }
        }
    }
});

export default styles;