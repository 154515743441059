import React from "react";
import {withStyles} from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import styles from "./styles";
import LoginHeader from "../Header/container";
import Footer from "../../../../common/Footer/component";
import { Link } from 'react-router-dom';

class LinkToTemplate extends React.Component {
    render() {
        const {
            classes
        } = this.props;

        const buildjs = '<script src="https://ep.i-ways-network.org/build.js"></script>'
        const buildcss = '<link href="https://ep.i-ways-network.org/build.css"></link>'
        const mustAdd = '<section id="privacy_cookie"></section>'

        return (
            <>
            <LoginHeader />
            <div className={classes.downloadwrapper}>
                <Grid container>
                    <h1 className={classes.title}>Template Created Successfuly</h1>
                    <Grid item xs={12} md={6}>
                        <div className={classes.guidlineswrapper}>
                            <h2>Download Files</h2>
                            <Link href='https://ep.i-ways-network.org/build.js' target="_blank" download="build.js">Download JS</Link>
                            <p>And</p>
                            <Link href='https://ep.i-ways-network.org/build.css' target="_blank" download="build.css">Download CSS</Link>

                            <span className="title">Guidlines</span>
                            <p>Add <span>build.js</span> file at the bottom of your project file where you want to see the Popup.</p>
                            <p>Add <span>build.css</span > file in the header to include the stylesheet to your popup.</p>


                        <p className="fileinstructions"><span>{mustAdd}</span> </p>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <h2>Include Links</h2>
                        <p>Please follow the below instructions to add Cookie Banner to your site</p>
                        <p className="fileinstructions">Copy and Add Script file at the end of you body tag in the root file.<span>{mustAdd}</span><span>{buildjs}</span> </p>
                        <p className="fileinstructions">Copy and Add CSS file at the end of you head tag in the root file.<span>{buildcss}</span></p>
                    </Grid>
                </Grid>
            </div>
            <Footer />
            </>
        );
    }
}

export default withStyles(styles)(LinkToTemplate);
