export const APPLICATION = {
    data: {
        applications: []
    },
    forms: {
        createApplication: {
            fields: {
                Name: {
                    label: "Name",
                    name: "Name",
                    placeholder: "Name",
                    value: ""
                },
                URL: {
                    label: "URL",
                    name: "URL",
                    placeholder: "URL",
                    value: ""
                },
            }
        }
    },
    UISettings: {
    }
}
export default APPLICATION;