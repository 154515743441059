import React from 'react';
import './settings.css'

export default class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { settingsPopup: true, categoriesData: '' };
  }
  enableCookieHandler() {
    this.setState({ settingsPopup: false })
  };
  componentDidMount() {
    const categoriesData = this.props.categories.map(item => {
      return {
        categoryName: item.label,
        categoryDetail: item.label,
        open: false,
      }
    })
    this.setState({ categoriesData: categoriesData })
  }

  changeCookie(index) {
    let data = this.state.categoriesData;
    data[index].open = !data[index].open
    this.setState({
      categories: data
    })
  }

  render() {
    const { categories, content, title } = this.props;
    const { categoriesData } = this.state;

    return (this.state.settingsPopup ? <div className="settingsmodal">
      <div className="header">
        <span>{title}</span>
      </div>
      <div className="wrapper">
        <div className="descrtiption">
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <p><b>Bitte entscheiden Sie, ob wir die nachstehenden Tools verwenden dürfen:</b></p>
        </div>
        {categoriesData && <div>
          {
            categoriesData.map((item, index) => (
              <div className="tooglehandler" key={index}>
                <div className="togglewrapper">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                  <h1>{item.categoryName}</h1>
                  <p style={{ margin: "4px 19px" }} onClick={() => this.changeCookie(index)}><i class="arrowdown" style={{
                    border: "solid black",
                    borderWidth: "0 3px 3px 0",
                    display: "inline-block",
                    padding: 3,
                    transform: item.open ? "rotate(-135deg)" : "rotate(45deg)"
                  }}></i></p>
                </div>
                {
                  item.open ?
                    <div>
                      <div className="linkedtext">  <span className="title">Anbieter:</span> Google LLC und Google Ireland Ltd.</div>
                      <div className="linkedtext">  <span className="title">Speicherdauer:</span> 14 Monate</div>
                      <div className="linkedtext">  <span className="title">Weitere Informationen:</span> Siehe <a href="https://policies.google.com/terms?hl=de" target="_blank">Nutzungsbedingungen</a> und <a href="https://policies.google.com/privacy?hl=de" target="_blank">Datenschutzerklärung</a> von Google.</div>
                      <p>{item.categoryDetail}</p>
                    </div> : <div></div>
                }
              </div>
            ))
          }

        </div>
        }
      </div>
      <button className="cookiesavebtn" style={{background: this.props.btnBGColor, color: this.props.btnFontColor} }onClick={this.enableCookieHandler.bind(this)} >{this.props.confirmText}</button>
    </div> : <span></span>)
  }
}
