import React, { Component } from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";


class CreateApplication extends Component {
    SubmitHandler = () => {
        this.props.submitFormHandler()
    }

    render() {
        const {
            classes,
            fields,
            textFieldChangeHandler,
        } = this.props;

        return (
            <div className={classes.polygonbg}>
                <Grid container className={classes.applicationWrapper} justify="center">
                    <Grid item xs={12} sm={12}>

                        <h1 className="heading">Create </h1>
                        <ValidatorForm
                            onSubmit={this.SubmitHandler}
                            autoComplete="off"
                        >

                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <CustomTextValidator
                                        id={fields.Name.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="text"
                                        placeholder={fields.Name.placeholder}
                                        name={fields.Name.name}
                                        value={fields.Name.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.Name.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.fields}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} md={6}>
                                    <CustomTextValidator
                                        id={fields.URL.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="text"
                                        placeholder={fields.URL.placeholder}
                                        name={fields.URL.name}
                                        value={fields.URL.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.URL.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.fields}
                                    />
                                </Grid>
                            </Grid>

                            <Button variant="contained" color="primary" type="submit" className={classes.submitBtn} >
                                Add
                            </Button>
                            <div className={classes.passwordOptions}>

                            </div>
                        </ValidatorForm>
                    </Grid>

                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(CreateApplication);