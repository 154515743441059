import {
    APPLICATION_PAGE_TEXT_CHANGE_HANDLER,
    APPLICATION_PAGE_SELECT_CHANGE_HANDLER,
    APPLICATION_PAGE_SAVE_ALL_HANDLER
} from "./actions";

export const applicationReducer = (state = [], action) => {
    switch (action.type) {

        case APPLICATION_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    createApplication: {
                        ...state.forms.createApplication,
                        fields: {
                            ...state.forms.createApplication.fields,
                            [action.key]: {
                                ...state.forms.createApplication.fields[action.key],
                                value: action.payload
                            }
                        }
                    }
                }
            };
        }

        case APPLICATION_PAGE_SELECT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    createApplication: {
                        ...state.forms.createApplication,
                        fields: {
                            ...state.forms.createApplication.fields,
                            [action.key]: {
                                ...state.forms.createApplication.fields[action.key],
                                value: (action.payload === null) ? [] : [action.payload]
                            }
                        }
                    }
                }
            }
        }

        case APPLICATION_PAGE_SAVE_ALL_HANDLER: {
            return {
                ...state,
                data: {
                    ...state.data,
                    applications: [...action.payload]
                }
            }
        }
        
        default: {
            return {
                ...state
            };
        }
    }
}