import { connect } from "react-redux";
import UpdateApplication from "./component";
import {
    textFieldChangeHandler,
    updateApplicationFields
} from "../actions";
import instance, { BASE_URL } from "../../../../../api/config";
import ls from 'local-storage';
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";

const mapStateToProps = state => ({
    fields: state.APPLICATION.forms.createApplication.fields,
})

const mapDispatchToProps = (dispatch, props) => ({
    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(textFieldChangeHandler(name, e.value));
    },
    submitFormHandler: (Name, URL, history) => {
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Updating Applications", "small"));
        let data = {
            name: Name,
            url: URL,
        }
        const id = ls.get('applicationId')
        instance.put(BASE_URL+'application/'+id, data)
        .then(res => {
            dispatch(handleLoadingSpinnerClose());
            window.location.replace('/user/app-all')
        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
    },
    updateApplicationFields: (value) => {
        dispatch(updateApplicationFields(value))
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitFormHandler: () => {
        dispatchProps.submitFormHandler(stateProps.fields.Name.value, stateProps.fields.URL.value, ownProps.history)
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(UpdateApplication);