export const DASHBOARD = {
    data: {
    },
    forms: {
        createTenplate: {
            fields: {
                Name: {
                    label: "Name",
                    name: "Name",
                    placeholder: "Name",
                    value: ""
                },
                URL: {
                    label: "URL",
                    name: "URL",
                    placeholder: "URL",
                    value: ""
                },
            }
        }
    },
    UISettings: {
    }
}
export default DASHBOARD;