import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const user_list = [
	{
		label: "Dashboard",
		icon: <HomeIcon />,
		url: "/user"
	},
	{
		label: "Application",
		icon: <HomeIcon />,
		url: "/user/app",
		children: [
			{
				label: "Add New",
				icon: <HomeIcon />,
				url: "/user/app-new",
				isSubMenu: true,
			},
			{
				label: "View All",
				icon: <HomeIcon />,
				url: "/user/app-all",
				isSubMenu: true,
			}
		]
	},
	{
		label: "Template",
		icon: <HomeIcon />,
		url: "/user/template",
		children: [
			{
				label: "Add New",
				icon: <HomeIcon />,
				url: "/user/template-new",
				isSubMenu: true,
			},
			{
				label: "View All",
				icon: <HomeIcon />,
				url: "/user/template-all",
				isSubMenu: true,
			}
		]
	},
	{
		label: "Category",
		icon: <HomeIcon />,
		url: "/user/template",
		children: [
			{
				label: "Add New",
				icon: <HomeIcon />,
				url: "/user/category-new",
				isSubMenu: true,
			},
			{
				label: "View All",
				icon: <HomeIcon />,
				url: "/user/category-all",
				isSubMenu: true,
			}
		]
	},
	{
		label: "Consent",
		icon: <HomeIcon />,
		url: "/user/consent",
		children: [
			{
				label: "View All",
				icon: <HomeIcon />,
				url: "/user/consent-all",
				isSubMenu: true,
			},
		]
	},
	{
		label: "Logout",
		icon: <ExitToAppIcon />
	}
];

export default user_list;
