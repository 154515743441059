import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import UpdateApplication from "../Update/container";

class AllApplications extends Component {

    state = {
        show: false,
        showDelete: false,
        currentId: "",
        anchorEl: null,
        editData:'',
        showEdit:false,
        cuurentData: null
    }

    componentDidMount = () => {
        this.props.fetchDataIfNeeded();
    }

    editHandler = (show,id) => {
        this.setState({
            showEdit:show,
            cuurentData: id ? id.rowData : null
        })
    }

    showAlertDialogDelete = (value, id) => {
        this.setState({
            showDelete: value,
            currentId: id
        })
    }

    deleteApplicationHandler = () => {
        this.setState({
            showDelete: false
        })
        
        this.props.deleteApplicationHandler(this.state.currentId)
    }

    render() {
        const { 
            classes,
            tableData
        } = this.props;

        const columns = ["Name", "URL",
            {
                name: "Action",
                label: "Action",
                options: {
                    customBodyRender: (props, data) => {
                        let rowDataValue = data.rowData[2]

                        return (
                            <div>
                                <Button className={classes.tableicons} color="primary" variant="outlined" onClick={()=>this.editHandler(true, data)}><EditIcon /></Button>
                                <Button className={classes.tableicons} color="primary" variant="outlined" onClick={() => this.showAlertDialogDelete(true, rowDataValue)}><DeleteIcon /></Button>
                            </div>
                        )
                    },
                }
            }
        ];

        const options = {
            search: false,
            download: false,
            print: false,
            viewColumns: false,
            filter: false,
            checkbox: false,
            selectableRowsHeader: false,
            selectableRows: false
        }

        return (
            <>
            <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>View all Applications</p>
                    <MUIDataTable 
                        title={"Applications"} 
                        data={tableData} 
                        columns={columns}
                        options={options}
                    />
                </div>
            </div>
            {
                this.state.showDelete &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showDelete}
                    showTitle={true}
                    title={"Delete Application"}
                    message={"Are you sure want to delete this application?"}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showAlertDialogDelete(false)}
                    closeAlertDialog={() => this.showAlertDialogDelete(false)}
                    applyAlertDialog={() => this.deleteApplicationHandler()}
                />
            }

            {
                this.state.showEdit &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showEdit}
                    showTitle={true}
                    hideApplyButton={true}
                    title={"Update Application"}
                    message={<UpdateApplication currentData={this.state.cuurentData}/>}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.editHandler(false, null)}
                    closeAlertDialog={() => this.editHandler(false, null)}
                />
            }
            </>
        )
    }
}

export default withStyles(styles)(AllApplications);