import instance, { BASE_URL } from "../../../../api/config";
export const TEMPLATE_PAGE_TEXT_CHANGE_HANDLER = "TEMPLATE_PAGE_TEXT_CHANGE_HANDLER";
export const TEMPLATE_PAGE_SELECT_CHANGE_HANDLER = "TEMPLATE_PAGE_SELECT_CHANGE_HANDLER";
export const TEMPLATE_PAGE_MULTI_SELECT_CHANGE_HANDLER = "TEMPLATE_PAGE_MULTI_SELECT_CHANGE_HANDLER";
export const TEMPLATE_PAGE_SAVE_ALL_HANDLER = "TEMPLATE_PAGE_SAVE_ALL_HANDLER";
export const TEMPLATE_PAGE_SAVE_APPLICATIONS_HANDLER = "TEMPLATE_PAGE_SAVE_APPLICATIONS_HANDLER";
export const TEMPLATE_PAGE_SAVE_CATEGORIES_HANDLER = "TEMPLATE_PAGE_SAVE_CATEGORIES_HANDLER";
export const TEMPLATE_PAGE_EDITOR_CHANGE_HANDLER = "TEMPLATE_PAGE_EDITOR_CHANGE_HANDLER";
export const TEMPLATE_PAGE_CONTENT_EDITOR_CHANGE_HANDLER = "TEMPLATE_PAGE_CONTENT_EDITOR_CHANGE_HANDLER"

export const TEMPLATE_PAGE_APPLICATION_CHANGE_HANDLER = "TEMPLATE_PAGE_APPLICATION_CHANGE_HANDLER";
export const TEMPLATE_PAGE_CATEGORIES_CHANGE_HANDLER = "TEMPLATE_PAGE_CATEGORIES_CHANGE_HANDLER";
export const TEMPLATE_PAGE_CATEGORIES_CHANGE_UPDATE_HANDLER = "TEMPLATE_PAGE_CATEGORIES_CHANGE_UPDATE_HANDLER";

export const textFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const selectFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: TEMPLATE_PAGE_SELECT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const saveTemplates = (value) => dispatch => {
    let newArray = value.template ? value.template.map((item) => { return [item.title, item.titleBgColor, item.titleFontColor, item.allowBtnColor, item.cancelBtnColor, item.contentDetails, item.mainContent, item._links.template.href] }) : []
    dispatch({
        type: TEMPLATE_PAGE_SAVE_ALL_HANDLER,
        payload: [...newArray],
    });
};

export const saveApplications = (value) => dispatch => {
    let data = value.application ? value.application.map(item => {
        return {
            value: item._links.application.href,
            label: item.name,
            link: item._links.application.href
        }
    }) : []
    dispatch({
        type: TEMPLATE_PAGE_SAVE_APPLICATIONS_HANDLER,
        payload: data,
    });
};

export const saveCategories = (value) => dispatch => {
    let data = value.category ? value.category.map(item => {
        return {
            value: item._links.category.href,
            label: item.categoryName
        }
    }) : []
    dispatch({
        type: TEMPLATE_PAGE_SAVE_CATEGORIES_HANDLER,
        payload: data,
    });
};

export const multiSelectFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: TEMPLATE_PAGE_MULTI_SELECT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const saveEditorTextHandler = (value) => dispatch => {
    dispatch({
        type: TEMPLATE_PAGE_EDITOR_CHANGE_HANDLER,
        payload: value,
    });
};

export const saveContentEditorTextHandler = (value) => dispatch => {
    dispatch({
        type: TEMPLATE_PAGE_CONTENT_EDITOR_CHANGE_HANDLER,
        payload: value,
    });
};

export const updateTemplateFields = (value) => dispatch => {
    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.title,
        key: 'Title'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.titleBgColor,
        key: 'TitleBgColor'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.titleFontColor,
        key: 'TitleFontColor'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.titleFontColor,
        key: 'TitleFontColor'
    })

    //get call to fetch application data
    // getApplications(value._links.application.href)


    instance.get(BASE_URL+'application')
    .then(res => {
        saveApplications(res.data._embedded)

        instance.get(value._links.application.href)
        .then(res => {
            dispatch({
                type: TEMPLATE_PAGE_APPLICATION_CHANGE_HANDLER,
                payload: res.data._links.application.href,
                key: 'Application'
            });
        })
        .catch(error => {
        })
    })
    .catch(error => {
    })
        
    

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.allowBtnText,
        key: 'AllowBtnText'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.allowBtnBgColor,
        key: 'AllowBtnBgColor'
    });

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.allowBtnFontColor,
        key: 'AllowBtnFontColor'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.cancelBtnFontColor,
        key: 'CancelBtnColor'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.cancelBtnText,
        key: 'CancelBtnText'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.cancelBtnBgColor,
        key: 'CancelBtnBgColor'
    })

    instance.get(value._links.categories.href)
    .then(res => {
        dispatch({
            type: TEMPLATE_PAGE_CATEGORIES_CHANGE_HANDLER,
            payload: res.data._embedded.category,
            key: 'Categories'
        });
    })
    .catch(error => {
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.contentDetails,
        key: 'contentDetails'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.contentDetailsBgColor,
        key: 'ContentDetailsBgColor'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.contentDetailsFontColor,
        key: 'ContentDetailsFontColor'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.mainContent,
        key: 'MainContent'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.mainContentBgColor,
        key: 'MainContentBgColor'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.mainContentFontColor,
        key: 'MainContentFontColor'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.popupBgColor,
        key: 'PopupBgColor'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.popupDiscription,
        key: 'PopupDiscription'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.popupFontColor,
        key: 'PopupFontColor'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.popupHeaderText,
        key: 'PopupHeaderText'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.googleAnalyticsId,
        key: 'GoogleAnalyticsId'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.popupBtnConfirm,
        key: 'PopupBtnConfirm'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.popupBtnConfirmBgColor,
        key: 'PopupBtnConfirmBgColor'
    })

    dispatch({
        type: TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value.popupBtnConfirmFontColor,
        key: 'PopupBtnConfirmFontColor'
    })
    dispatch({
        type: TEMPLATE_PAGE_CATEGORIES_CHANGE_UPDATE_HANDLER,
        payload: true,
    })

};

export const setUpdateFalse = () => dispatch => {
    // dispatch({
    //     type: TEMPLATE_PAGE_CATEGORIES_CHANGE_UPDATE_HANDLER,
    //     payload: false,
    // })
}