import axios from "axios";

import {getAppStoreValue} from "../components/common/Helpers";
// Local Server
// const BASE_HOST = 'localhost';

// Localhost Server
// const BASE_HOST = "127.0.0.1";

// Dev Server
export const BASE_HOST = "ep-stage-backend.i-ways-network.org";

export const BASE_URL = "https://" + BASE_HOST + "/";



const headers = {
    "Content-Type": "application/JSON",
    "Access-Control-Allow-Origin": "*",
    "X-Forwarded-Proto": "https"
};

const instance = axios.create({
    baseURL: BASE_URL,
    headers: headers
});


instance.interceptors.request.use(
    function (request) {
        // request.httpsAgent = new https.Agent({
        //     rejectUnauthorized: false,
        //     cert: file,
        // });
        let token = getAppStoreValue("sessionToken").toString();
        if (!!token) {
            request.headers.authorization = `Bearer ${token}`;
        } else {
            request.config = { headers: { "Content-Type": "multipart/form-data" } };
        }
        // Do something with response data
        return request;
    },
    function (error) {
        // Do something with response error
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    function (response) {
        try {
            var res = JSON.parse(response.request.responseText.replace(/("[^"]*"\s*:\s*)(\d{16,})/g, '$1"$2"'))
            response.data.responseObj = res.responseObj;
        }
        catch (e) {
            console.log("Error Parsing json", e);
        }


        return response;
    },
    function (error) {
        if (!!error.response) {
            let status = error.response.status;
            switch (status) {
                case 401:
                    localStorage.setItem("isAuthenticated", false);
                    localStorage.setItem("token", "");
                    window.location.href = "/login";
                    break;
                default:
            }
        }
        return Promise.reject(error);
    }
);

export default instance;