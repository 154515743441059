import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import {Redirect, Switch} from "react-router-dom";
import PrivateRoute from "../../common/PrivateRouteContainer";
import DashboardLanding from "./Landing/container";
import LoginHeader from "../../ITool/Dashboard/common/Header/container";
import Footer from "../../common/Footer/component";
import AllApplications from "./Application/ViewAll/container";
import CreateApplication from "./Application/AddNew/container";
import CreateTemplate from "./Template/AddNew/container";
import AllTemplates from "./Template/ViewAll/container";
import CreateCategory from "./Category/AddNew/container";
import AllCategory from "./Category/ViewAll/container";
import AllConsents from "./Consent/ViewAll/container";

class UserDashboard extends Component {

    render() {
        return (
            <>
                <LoginHeader />
                <Switch>
                    <PrivateRoute path={`/user/dashboard`} component={DashboardLanding} />
                    <PrivateRoute path={`/user/app-all`} component={AllApplications} />
                    <PrivateRoute path={`/user/app-new`} component={CreateApplication} />
                    <PrivateRoute path={`/user/template-new`} component={CreateTemplate} />
                    <PrivateRoute path={`/user/template-all`} component={AllTemplates} />
                    <PrivateRoute path={`/user/category-new`} component={CreateCategory} />
                    <PrivateRoute path={`/user/category-all`} component={AllCategory} />
                    <PrivateRoute path={`/user/consent-all`} component={AllConsents} />

                    <Redirect to="/user/dashboard"/>
                </Switch>
                <Footer />
            </>
        )
    }
}

export default withStyles(styles)(UserDashboard);