import {LOGIN} from "../components/Login/initialState";
import {ALERT_DIALOG} from "../components/common/ReduxBased/confirmationDialog/initialState";
import {LOADING_SPINNER} from "./../components/common/ReduxBased/LoadingSpinner/initialState";
import {DASHBOARD} from "../components/ITool/Dashboard/initialState";
import {LANDING_DASHBOARD} from "../components/ITool/Dashboard/Landing/initialState";
import {APPLICATION} from "../components/ITool/Dashboard/Application/initialState";
import {TEMPLATE} from "../components/ITool/Dashboard/Template/initialState";
import {CATEGORY} from "../components/ITool/Dashboard/Category/initialState";
import {CONSENT} from "../components/ITool/Dashboard/Consent/initialState";

const initalState = {
    LOGIN: LOGIN,
    ALERT_DIALOG: ALERT_DIALOG,
    LOADING_SPINNER: LOADING_SPINNER,
    DASHBOARD: DASHBOARD,
    LANDING_DASHBOARD: LANDING_DASHBOARD,
    APPLICATION: APPLICATION,
    TEMPLATE: TEMPLATE,
    CATEGORY: CATEGORY,
    CONSENT: CONSENT
};

export default initalState;
