import { connect } from "react-redux";
import AllTemplates from "./component";
import instance, {BASE_URL} from "../../../../../api/config";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import {
    saveTemplates,
    updateTemplateFields
} from "../actions";
import ls from 'local-storage'
const mapStateToProps = state => ({
    tableData: state.TEMPLATE.data.templates,
})

const mapDispatchToProps = (dispatch, props) => ({
    fetchDataIfNeeded: (id) => {
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Loading Templates", "small"));
        instance.get(BASE_URL+"template")
        .then(res => {
            dispatch(saveTemplates(res.data._embedded))
            dispatch(handleLoadingSpinnerClose());
        })
        .catch(err => {
            dispatch(handleLoadingSpinnerClose());
        })
    },
    deleteApplicationHandler: (id) => {
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Deleting template", "small"));
        instance.delete(id)
        .then(res => {
            dispatch(handleLoadingSpinnerClose());
            window.location.reload();
        })
        .catch(err => {
            dispatch(handleLoadingSpinnerClose());
        })
    },
    updateTemplateHandler: async (id, history) => { //todo
        const origin = "https://ep-stage-backend.i-ways-network.org" //window.location.origin
        const location = id.replace(origin, '').replace('template/', '').replace('/', '')
        ls.set('templateId', location)
        await instance.get(BASE_URL+"template/"+location)
        .then(res => {
            dispatch(updateTemplateFields(res.data))
            history.push('/user/template-new')
        })
        .catch(err => {
        })
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(AllTemplates);