import ls from 'local-storage'
export const APPLICATION_PAGE_TEXT_CHANGE_HANDLER = "APPLICATION_PAGE_TEXT_CHANGE_HANDLER";
export const APPLICATION_PAGE_SELECT_CHANGE_HANDLER = "APPLICATION_PAGE_SELECT_CHANGE_HANDLER";
export const APPLICATION_PAGE_SAVE_ALL_HANDLER = "APPLICATION_PAGE_SAVE_ALL_HANDLER";

export const textFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: APPLICATION_PAGE_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const selectFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: APPLICATION_PAGE_SELECT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const saveApplications = (value) => dispatch => {
    let newArray = value.application ? value.application.map((item) => {return [item.name, item.url, item._links.application.href]}) : []
    dispatch({
        type: APPLICATION_PAGE_SAVE_ALL_HANDLER,
        payload: [...newArray],
    });
};

export const updateApplicationFields = (value) => dispatch => {
    const data = value.length ? value : []
    dispatch({
        type: APPLICATION_PAGE_TEXT_CHANGE_HANDLER,
        payload: data[0] ? data[0]: "",
        key: 'Name'
    });

    dispatch({
        type: APPLICATION_PAGE_TEXT_CHANGE_HANDLER,
        payload: data[1] ? data[1]: "",
        key: 'URL'
    });

    const origin = "https://ep-stage-backend.i-ways-network.org" //window.location.origin
    const id = data[2] ? data[2]: ""
    const location = id.replace(origin, '').replace('application/', '').replace('/', '')
    ls.set('applicationId', location)
};