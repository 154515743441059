import { connect } from "react-redux";
import DashboardLanding from "./component";
import instance, {BASE_URL} from "../../../../api/config";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../common/ReduxBased/LoadingSpinner/actions";
import {
    saveDashboardCount
} from './actions';
import ls from 'local-storage'


const mapStateToProps = state => ({
    dashboardCount: state.LANDING_DASHBOARD.data.dashboardCount
})


const mapDispatchToProps = (dispatch, props) => ({
    fetchDataIfNeeded: () => {
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Loading Data...", "small"));
        instance.get(BASE_URL+'auction-event-dashboard/counts')
            .then(res => {
                dispatch(saveDashboardCount(res.data.data))
                dispatch(handleLoadingSpinnerClose());
            })
            .catch(error => {
                ls.set('TokenExpired401403', error.response.status);
                dispatch(handleLoadingSpinnerClose());
            })
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(DashboardLanding);