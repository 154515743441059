export const styles = theme => ({
    logintitle: {
        marginTop: 0,
        marginBottom: 0,
        color: theme.palette.primary.main,
        fontSize: 48,
    },
    loginsubtitle: {
        marginBottom: 7,
    },
    fields: {
        marginBottom: 13,
        padding: 13,
    },
    colorFields: {
        marginBottom: 13,
        padding: 13,
        "& input": {
            height: "100% !important",
        }
    },
    applicationWrapper: {
        padding: "32px",
    },
    polygonbg: {
        backgroundSize: "cover",

    },
    submitBtn: {
        display: "block",
        width: "200px",
        borderRadius: "5px !important",
        marginTop: 10,
        marginLeft: 13,
    },
    labels: {
        paddingLeft: 13,
    }
})