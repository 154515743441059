export const TEMPLATE = {
    data: {
        templates: []
    },
    forms: {
        createTemplate: {
            fields: {
                Title: {
                    label: "Title",
                    name: "Title",
                    placeholder: "Title",
                    value: ""
                },
                TitleBgColor: {
                    label: "Title Background Color",
                    name: "TitleBgColor",
                    placeholder: "Title Background Color",
                    value: "#fff"
                },
                TitleFontColor: {
                    label: "Title Font Color",
                    name: "TitleFontColor",
                    placeholder: "Title Font Color",
                    value: "#fff"
                },
                Application: {
                    label: "Application",
                    name: "Application",
                    placeholder: "Application",
                    value: [],
                    options: []
                },
                AllowBtnText: {
                    label: "Allow Button Text",
                    name: "AllowBtnText",
                    placeholder: "Allow Button Text",
                    value: ""
                },
                AllowBtnBgColor: {
                    label: "Allow Button BG Color",
                    name: "AllowBtnBgColor",
                    placeholder: "Allow Button BG Color",
                    value: "#fff"
                },
                AllowBtnFontColor: {
                    label: "Allow Button Color",
                    name: "AllowBtnFontColor",
                    placeholder: "Allow Button Color",
                    value: "#fff"
                },
                CancelBtnColor: {
                    label: "Cancel Button Color",
                    name: "CancelBtnColor",
                    placeholder: "Cancel Button Color",
                    value: "#fff"
                },
                CancelBtnText: {
                    label: "Cancel Button Text",
                    name: "CancelBtnText",
                    placeholder: "Cancel Button Text",
                    value: ""
                },
                CancelBtnBgColor: {
                    label: "Cancel Button BG Color",
                    name: "CancelBtnBgColor",
                    placeholder: "Cancel Button BG Color",
                    value: "#fff"
                },
                Categories: {
                    label: "Categories",
                    name: "Categories",
                    placeholder: "Categories",
                    value: [],
                    options: []
                },
                contentDetails: {
                    label: "Content Details",
                    name: "contentDetails",
                    placeholder: "Content Details",
                    value: ""
                },
                ContentDetailsBgColor: {
                    label: "Content Background Color",
                    name: "ContentDetailsBgColor",
                    placeholder: "Content Background Color",
                    value: "#fff"
                },
                ContentDetailsFontColor: {
                    label: "Content Font Color",
                    name: "ContentDetailsFontColor",
                    placeholder: "Content Font Color",
                    value: "#fff"
                },
                MainContent: {
                    label: "Main Content",
                    name: "MainContent",
                    placeholder: "Main Content",
                    value: ""
                },
                MainContentBgColor: {
                    label: "Main Content BG Color",
                    name: "MainContentBgColor",
                    placeholder: "Main Content BG Color",
                    value: "#fff"
                },
                MainContentFontColor: {
                    label: "Main Content Font Color",
                    name: "MainContentFontColor",
                    placeholder: "Main Content Font Color",
                    value: "#fff"
                },
                PopupBgColor: {
                    label: "Popup BG Color",
                    name: "PopupBgColor",
                    placeholder: "Popup BG Color",
                    value: "#fff"
                },
                PopupDiscription: {
                    label: "Popup Description",
                    name: "PopupDiscription",
                    placeholder: "Popup Description",
                    value: ""
                },
                PopupFontColor: {
                    label: "Popup Font Color",
                    name: "PopupFontColor",
                    placeholder: "Popup Font Color",
                    value: "#fff"
                },
                PopupHeaderText: {
                    label: "Popup Title",
                    name: "PopupHeaderText",
                    placeholder: "PopupHeaderText",
                    value: ""
                },
                GoogleAnalyticsId: {
                    label: "Google Analytics ID",
                    name: "GoogleAnalyticsId",
                    placeholder: "Google Analytics ID",
                    value: ""
                },
                PopupBtnConfirm: {
                    label: "Popup Button Text",
                    name: "PopupBtnConfirm",
                    placeholder: "Popup Button Text",
                    value: ""
                },
                PopupBtnConfirmBgColor: {
                    label: "Popup Button BG Color",
                    name: "PopupBtnConfirmBgColor",
                    placeholder: "Popup Button BG Color",
                    value: "#fff"
                },
                PopupBtnConfirmFontColor: {
                    label: "Popup Button Font Color",
                    name: "PopupBtnConfirmFontColor",
                    placeholder: "Popup Button Font Color",
                    value: "#fff"
                },
            }
        }
    },
    UISettings: {
        isUpdate: false
    }
}
export default TEMPLATE;