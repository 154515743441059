import { connect } from "react-redux";
import CreateApplication from "./component";
import {
    textFieldChangeHandler,
} from "../actions";
import instance, { BASE_URL } from "../../../../../api/config";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";

const mapStateToProps = state => ({
    fields: state.APPLICATION.forms.createApplication.fields,
})

const mapDispatchToProps = (dispatch, props) => ({
    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(textFieldChangeHandler(name, e.value));
    },
    submitFormHandler: (Name, URL, history) => {
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Creating Applications", "small"));
        let data = {
            name: Name,
            url: URL,
        }
        instance.post(BASE_URL+'application', data)
        .then(res => {
            dispatch(handleLoadingSpinnerClose());
            history.push('/user/app-all')
        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitFormHandler: () => {
        dispatchProps.submitFormHandler(stateProps.fields.Name.value, stateProps.fields.URL.value, ownProps.history)
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(CreateApplication);