import React, { Component } from 'react';
import {BrowserRouter as Router, Switch} from "react-router-dom";
import Login  from "./Login/container";
import UserDashboard from "./ITool/Dashboard/container";

import { styles } from "./style";
import "./style.css";
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { Theme } from "./../resources/theme";
import CssBaseline from "@material-ui/core/CssBaseline";

import PrivateRoute from "./common/PrivateRouteContainer";
import PublicRoute from "./common/PublicRouteContainer";
import LoadingSpinner from "./common/ReduxBased/LoadingSpinner/container";
import LinkToTemplate from "./ITool/Dashboard/common/Success/container";

class App extends Component {

  state = {
    isLogin: true,
    selectedTheme: Theme,
  }
  render() {
    
    return (
      <>
          <Router>
            <MuiThemeProvider theme={Theme}>
              <MuiThemeProvider theme={this.state.selectedTheme}>
                <CssBaseline />
              
                <Switch>
                    <PublicRoute exact path={`/`} component={Login}/>
                    <PublicRoute path={`/login`} component={Login}/>
                    <PrivateRoute path={`/user`} component={UserDashboard}/>
                    <PrivateRoute path={`/download`} component={LinkToTemplate} />
                </Switch>
                <LoadingSpinner />
                </MuiThemeProvider>
              </MuiThemeProvider>
          </Router>
      </>
    );
  }
}

export default withStyles(styles)(App);
