import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";

class DashboardLanding extends Component {

    componentDidMount = () => {
    }
    
    render() {
        const {
            classes,
            dashboardCount
        } = this.props;

        return (
            <div className={classes.dashboardwrapper}>
            <h1 className="title">Dashboard</h1>
            </div>
        )
    }
}

export default withStyles(styles)(DashboardLanding);