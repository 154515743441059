import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../common/ReduxBased/LoadingSpinner/actions";

import instance, { BASE_URL } from "../../api/config";
import { reverseString } from "./../common/Helpers";
export const LOGIN_PAGE_TEXT_CHANGE_HANDLER = "LOGIN_PAGE_TEXT_CHANGE_HANDLER";
export const LOGIN_SUBMIT_FORM_HANDLER = "LOGIN_SUBMIT_FORM_HANDLER";
export const LOGIN_PAGE_ERROR_HANDLER = "LOGIN_PAGE_ERROR_HANDLER";

export const textFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: LOGIN_PAGE_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const submitFormHandler = (value) => dispatch => {
    let resObj = {};
    let keys_needed = ["sessionToken", "firstName", "id"];
    
    dispatch(handleLoadingSpinnerOpenWithOptions(true, "Loading...", "large"));
    instance.post(BASE_URL + 'users/login?email='+value.email+'&password='+value.password)
        .then(res => {
            if(res.data.email === null) {
                dispatch({
                    type: LOGIN_PAGE_ERROR_HANDLER,
                    payload: 'Incorrect email/password',
                });
            }
            dispatch(handleLoadingSpinnerClose());
            keys_needed.map(item => {
                resObj[reverseString(item)] = res.data.userToken[item];
                return item;
            });
            resObj[reverseString("id")] = res.data.id;
            resObj[reverseString("firstName")] = res.data.userName ? res.data.userName : res.data.userName.toUpperCase();
            localStorage.setItem(reverseString("app_store"), JSON.stringify(resObj));
            window.location.replace('/user')

        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
        
}