import { connect } from "react-redux";
import AllTemplates from "./component";
import {
    textFieldChangeHandler,
    selectFieldChangeHandler,
    multiSelectFieldChangeHandler,
    saveApplications,
    saveCategories,
    saveEditorTextHandler,
    setUpdateFalse,
    saveContentEditorTextHandler
} from "../actions";
import instance, { BASE_URL } from "../../../../../api/config";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import ls from 'local-storage'
const mapStateToProps = state => ({
    fields: state.TEMPLATE.forms.createTemplate.fields,
    isUpdate: state.TEMPLATE.UISettings.isUpdate
})

const mapDispatchToProps = (dispatch, props) => ({
    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(textFieldChangeHandler(name, e.value));
    },
    selectFieldChangeHandler: (name, e) => {
        if (name === 'Categories') {
            dispatch(multiSelectFieldChangeHandler(name, e));
        }else {
            dispatch(selectFieldChangeHandler(name, e));
        }
    },
    fetchDataIfNeeded: async () => {
        await instance.get(BASE_URL+'application')
        .then(res => {
            dispatch(saveApplications(res.data._embedded))
        })
        .catch(error => {
        })

        //saveing categories
        await instance.get(BASE_URL+'category')
        .then(res => {
            dispatch(saveCategories(res.data._embedded))
        })
        .catch(error => {
        })

        dispatch(setUpdateFalse())
    },
    submitUpdateHandler: (Title, TitleBgColor, TitleFontColor, Application, AllowBtnColor, AllowBtnText, AllowBtnBgColor, CancelBtnColor, CancelBtnText, CancelBtnBgColor, Categories, contentDetails, ContentDetailsBgColor, ContentDetailsFontColor, MainContent, MainContentBgColor, MainContentFontColor, PopupBgColor, PopupDiscription, PopupFontColor, PopupHeaderText, GoogleAnalyticsId, PopupBtnConfirm, PopupBtnConfirmBgColor, PopupBtnConfirmFontColor, history) => {
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Updating Template", "small"));
        var categoryKeys = []
        Categories.forEach(item => {
            if (item.value) {
                categoryKeys.push(item.value);
            }
        });

        let data = {
            allowBtnFontColor: AllowBtnColor,
            allowBtnBgColor: AllowBtnBgColor,
            allowBtnText: AllowBtnText,
            application: Application[0] ? Application[0].value : "",
            cancelBtnFontColor: CancelBtnColor,
            cancelBtnBgColor: CancelBtnBgColor,
            cancelBtnText: CancelBtnText,
            categories: categoryKeys,
            contentDetails: contentDetails,
            contentDetailsBgColor: ContentDetailsBgColor,
            contentDetailsFontColor: ContentDetailsFontColor,
            mainContent: MainContent,
            mainContentBgColor: MainContentBgColor,
            mainContentFontColor: MainContentFontColor,
            title: Title,
            titleBgColor: TitleBgColor,
            titleFontColor: TitleFontColor,
            popupBgColor: PopupBgColor,
            popupDiscription: PopupDiscription,
            popupFontColor: PopupFontColor,
            popupHeaderText: PopupHeaderText,
            googleAnalyticsId: GoogleAnalyticsId,
            popupBtnConfirm: PopupBtnConfirm,
            popupBtnConfirmBgColor: PopupBtnConfirmBgColor,
            popupBtnConfirmFontColor: PopupBtnConfirmFontColor
          }
          const templateId = ls.get('templateId')
        instance.patch(BASE_URL+'template/'+templateId, data)
        .then(res => {
            dispatch(handleLoadingSpinnerClose());
            window.location.replace('/user/template-all')
        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
    },
    submitFormHandler: (Title, TitleBgColor, TitleFontColor, Application, AllowBtnColor, AllowBtnText, AllowBtnBgColor, CancelBtnColor, CancelBtnText, CancelBtnBgColor, Categories, contentDetails, ContentDetailsBgColor, ContentDetailsFontColor, MainContent, MainContentBgColor, MainContentFontColor, PopupBgColor, PopupDiscription, PopupFontColor, PopupHeaderText, GoogleAnalyticsId, PopupBtnConfirm, PopupBtnConfirmBgColor, PopupBtnConfirmFontColor, history) => {
        
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Saving Template", "small"));
        var categoryKeys = []
        Categories.forEach(item => {
            if (item.value) {
                categoryKeys.push(item.value);
            }
        });

        let data = {
            allowBtnFontColor: AllowBtnColor,
            allowBtnBgColor: AllowBtnBgColor,
            allowBtnText: AllowBtnText,
            application: Application[0] ? Application[0].value : "",
            cancelBtnFontColor: CancelBtnColor,
            cancelBtnBgColor: CancelBtnBgColor,
            cancelBtnText: CancelBtnText,
            categories: categoryKeys,
            mainContent: MainContent,
            mainContentBgColor: MainContentBgColor,
            mainContentFontColor: MainContentFontColor,
            title: Title,
            titleBgColor: TitleBgColor,
            titleFontColor: TitleFontColor,
            popupBgColor: PopupBgColor,
            popupDiscription: PopupDiscription,
            popupFontColor: PopupFontColor,
            popupHeaderText: PopupHeaderText,
            googleAnalyticsId: GoogleAnalyticsId,
            popupBtnConfirm: PopupBtnConfirm,
            popupBtnConfirmBgColor: PopupBtnConfirmBgColor,
            popupBtnConfirmFontColor: PopupBtnConfirmFontColor
          }
        instance.post(BASE_URL+'template', data)
        .then(res => {
            dispatch(handleLoadingSpinnerClose());
            history.push('/user/template-all')
        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
    },
    saveEditorTextHandler: (value) => {
        dispatch(saveEditorTextHandler(value))
    },
    saveContentEditorTextHandler: (value) => {
        dispatch(saveContentEditorTextHandler(value))
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitFormHandler: () => {
        dispatchProps.submitFormHandler(
            stateProps.fields.Title.value,
            stateProps.fields.TitleBgColor.value,
            stateProps.fields.TitleFontColor.value,
            stateProps.fields.Application.value,
            stateProps.fields.AllowBtnFontColor.value,
            stateProps.fields.AllowBtnText.value,
            stateProps.fields.AllowBtnBgColor.value,
            stateProps.fields.CancelBtnColor.value,
            stateProps.fields.CancelBtnText.value,
            stateProps.fields.CancelBtnBgColor.value,
            stateProps.fields.Categories.value,
            stateProps.fields.contentDetails.value,
            stateProps.fields.ContentDetailsBgColor.value,
            stateProps.fields.ContentDetailsFontColor.value,
            stateProps.fields.MainContent.value,
            stateProps.fields.MainContentBgColor.value,
            stateProps.fields.MainContentFontColor.value,
            stateProps.fields.PopupBgColor.value,
            stateProps.fields.PopupDiscription.value,
            stateProps.fields.PopupFontColor.value,
            stateProps.fields.PopupHeaderText.value,
            stateProps.fields.GoogleAnalyticsId.value,
            stateProps.fields.PopupBtnConfirm.value,
            stateProps.fields.PopupBtnConfirmBgColor.value,
            stateProps.fields.PopupBtnConfirmFontColor.value,
            ownProps.history)
    },
    submitUpdateHandler: () => {
        dispatchProps.submitUpdateHandler(
            stateProps.fields.Title.value,
            stateProps.fields.TitleBgColor.value,
            stateProps.fields.TitleFontColor.value,
            stateProps.fields.Application.value,
            stateProps.fields.AllowBtnFontColor.value,
            stateProps.fields.AllowBtnText.value,
            stateProps.fields.AllowBtnBgColor.value,
            stateProps.fields.CancelBtnColor.value,
            stateProps.fields.CancelBtnText.value,
            stateProps.fields.CancelBtnBgColor.value,
            stateProps.fields.Categories.value,
            stateProps.fields.contentDetails.value,
            stateProps.fields.ContentDetailsBgColor.value,
            stateProps.fields.ContentDetailsFontColor.value,
            stateProps.fields.MainContent.value,
            stateProps.fields.MainContentBgColor.value,
            stateProps.fields.MainContentFontColor.value,
            stateProps.fields.PopupBgColor.value,
            stateProps.fields.PopupDiscription.value,
            stateProps.fields.PopupFontColor.value,
            stateProps.fields.PopupHeaderText.value,
            stateProps.fields.GoogleAnalyticsId.value,
            stateProps.fields.PopupBtnConfirm.value,
            stateProps.fields.PopupBtnConfirmBgColor.value,
            stateProps.fields.PopupBtnConfirmFontColor.value,
            ownProps.history)
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(AllTemplates);