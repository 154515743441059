import React, { Component } from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ContentState, convertToRaw, convertFromRaw } from 'draft-js'
import { convertToHTML, convertFromHTML } from 'draft-convert';
import MUIEditor, { MUIEditorState, toolbarControlTypes } from 'react-mui-draft-wysiwyg'
import MainContent from "./MainContent/container";

class CreateCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: MUIEditorState.createEmpty(),
        }
    }
    
    SubmitHandler = () => {
        this.props.submitFormHandler()
    }

    onChange = (newState) => {
        this.setState({
            editorState: newState
        })
        let currentContentAsHTML = convertToHTML(this.state.editorState.getCurrentContent());
        this.props.saveEditorTextHandler(currentContentAsHTML)
    }

    componentDidMount = async () => {
        let convertedData = convertFromHTML(this.props.fields.Detail.value);
        let convertedRawData = convertToRaw(convertedData);
        const rawContents = {
            blocks: [...convertedRawData.blocks],
            entityMap: {},
        }
        this.setState({
            editorState: MUIEditorState.createWithContent(
                {},
                convertFromRaw(rawContents),
              )
        })
        let currentContentAsHTML = convertToHTML(this.state.editorState.getCurrentContent());
        this.props.saveEditorTextHandler(currentContentAsHTML);
    }

    render() {
        const {
            classes,
            fields,
            textFieldChangeHandler,
        } = this.props;

        const config = {
            toolbar: {
               controls: [
                toolbarControlTypes.undo,
                toolbarControlTypes.redo,
                toolbarControlTypes.divider,
                toolbarControlTypes.bold,
                toolbarControlTypes.italic,
                toolbarControlTypes.underline,
                toolbarControlTypes.strikethrough,
                toolbarControlTypes.fontColor,
                toolbarControlTypes.fontBackgroundColor,
                toolbarControlTypes.divider,
                toolbarControlTypes.linkAdd,
                toolbarControlTypes.linkRemove,
                toolbarControlTypes.divider,
                toolbarControlTypes.blockType,
                toolbarControlTypes.fontSize,
                toolbarControlTypes.fontFamily,
                toolbarControlTypes.textAlign,
                toolbarControlTypes.divider,
                toolbarControlTypes.unorderedList,
                toolbarControlTypes.orderedList,
                 ],
               },
               editor: {
                className: 'texteditor',
              },
        };

        return (
            <div className={classes.polygonbg}>
                <Grid container className={classes.applicationWrapper} justify="center">
                    <Grid item xs={12} sm={12}>

                        <h1 className="heading">Create </h1>
                        <ValidatorForm
                            onSubmit={this.SubmitHandler}
                            autoComplete="off"
                        >

                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <span className={classes.labels}>{fields.Title.label}</span>
                                    <CustomTextValidator
                                        id={fields.Title.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="text"
                                        placeholder={fields.Title.placeholder}
                                        name={fields.Title.name}
                                        value={fields.Title.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.Title.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.fields}
                                    />
                                </Grid>

                                
                                <Grid item xs={12} md={6}>
                                    <span className={classes.labels}>{fields.Name.label}</span>
                                    <CustomTextValidator
                                        id={fields.Name.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="text"
                                        placeholder={fields.Name.placeholder}
                                        name={fields.Name.name}
                                        value={fields.Name.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.Name.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.fields}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                {/* <label className={classes.labels}>{fields.Detail.label}</label>
                                    <div className={classes.texteditorwrapper}>
                                        <MUIEditor editorState={this.state.editorState} 
                                            onChange={this.onChange}
                                            config={config}
                                        />
                                    </div> */}
                                    <MainContent />
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <span className={classes.labels}>{fields.BgColor.label}</span>
                                    <CustomTextValidator
                                        id={fields.BgColor.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="color"
                                        placeholder={fields.BgColor.placeholder}
                                        name={fields.BgColor.name}
                                        value={fields.BgColor.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.BgColor.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.colorFields}
                                    />
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <span className={classes.labels}>{fields.FontColor.label}</span>
                                    <CustomTextValidator
                                        id={fields.FontColor.name}
                                        validations={true}
                                        fullWidth={true}
                                        type="color"
                                        placeholder={fields.FontColor.placeholder}
                                        name={fields.FontColor.name}
                                        value={fields.FontColor.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.FontColor.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className={classes.colorFields}
                                    />
                                </Grid>
                            </Grid>

                            <Button variant="contained" color="primary" type="submit" className={classes.submitBtn} >
                                Add
                            </Button>
                            <div className={classes.passwordOptions}>

                            </div>
                        </ValidatorForm>
                    </Grid>

                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(CreateCategory);