import ls from 'local-storage'
export const CATEGORY_PAGE_TEXT_CHANGE_HANDLER = "CATEGORY_PAGE_TEXT_CHANGE_HANDLER";
export const CATEGORY_PAGE_SELECT_CHANGE_HANDLER = "CATEGORY_PAGE_SELECT_CHANGE_HANDLER";
export const CATEGORY_PAGE_SAVE_ALL_HANDLER = "CATEGORY_PAGE_SAVE_ALL_HANDLER";
export const CATEGORY_PAGE_EDITOR_TEXT_CHANGE_HANDLER = "CATEGORY_PAGE_EDITOR_TEXT_CHANGE_HANDLER";

export const textFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: CATEGORY_PAGE_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const selectFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: CATEGORY_PAGE_SELECT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const saveCategories = (value) => dispatch => {
    let newArray = value.category ? value.category.map((item) => {return [item.categoryTitle, item.categoryName, item.categoryDetail,item.categoryNameBgColor, item.categoryNameFontColor, item._links.category.href]}) : []
    dispatch({
        type: CATEGORY_PAGE_SAVE_ALL_HANDLER,
        payload: [...newArray],
    });
};

export const updateCategoryFields = (value) => dispatch => {
    const data = value.length ? value : []

    dispatch({
        type: CATEGORY_PAGE_TEXT_CHANGE_HANDLER,
        payload: data[0] ? data[0]: "",
        key: 'Title'
    });

    dispatch({
        type: CATEGORY_PAGE_TEXT_CHANGE_HANDLER,
        payload: data[1] ? data[1]: "",
        key: 'Name'
    });

    dispatch({
        type: CATEGORY_PAGE_TEXT_CHANGE_HANDLER,
        payload: data[2] ? data[2]: "",
        key: 'Detail'
    });

    dispatch({
        type: CATEGORY_PAGE_TEXT_CHANGE_HANDLER,
        payload: data[3] ? data[3]: "",
        key: 'BgColor'
    });

    dispatch({
        type: CATEGORY_PAGE_TEXT_CHANGE_HANDLER,
        payload: data[4] ? data[4]: "",
        key: 'FontColor'
    });

    const origin = "https://ep-stage-backend.i-ways-network.org" //window.location.origin
    const id = data[5] ? data[5]: ""
    const location = id.replace(origin, '').replace('category/', '').replace('/', '')
    ls.set('categoryId', location)
};

export const saveEditorTextHandler = (value) => dispatch => {
    dispatch({
        type: CATEGORY_PAGE_EDITOR_TEXT_CHANGE_HANDLER,
        payload: value,
    });
};