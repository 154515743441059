export const CATEGORY = {
    data: {
        categories: []
    },
    forms: {
        createCategory: {
            fields: {
                Detail: {
                    label: "Detail",
                    name: "Detail",
                    placeholder: "Detail",
                    value: ""
                },
                Title: {
                    label: "Title",
                    name: "Title",
                    placeholder: "Title",
                    value: ""
                },
                Name: {
                    label: "Name",
                    name: "Name",
                    placeholder: "Name",
                    value: ""
                },
                BgColor: {
                    label: "Title Background Color",
                    name: "BgColor",
                    placeholder: "Background Color",
                    value: ""
                },
                FontColor: {
                    label: "Title Font Color",
                    name: "FontColor",
                    placeholder: "Font Color",
                    value: ""
                },
            }
        }
    },
    UISettings: {
    }
}
export default CATEGORY;