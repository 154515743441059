export const styles = theme => ({
    logintitle: {
        marginTop: 0,
        marginBottom: 0,
        color: theme.palette.primary.main,
        fontSize: 48,
    },
    loginsubtitle: {
        marginBottom: 7,
    },
    fields: {
        marginBottom: 13,
        padding: 13,
    },
    loginheaderlogo: {
        maxWidth: 200,
        marginBottom: 30,
    },
    applicationWrapper: {
        padding: "32px",
    },
    polygonbg: {
        backgroundSize: "cover",

    },
    submitBtn: {
        display: "block",
        width: "200px",
        borderRadius: "5px !important",
        marginTop: 10,
        marginLeft: 13,
    },
    signupPageBtn: {
        display: "block",
        width: "100%",
        borderRadius: "5px !important",
        marginBottom: 7,
        fontSize: 20,
        textTransform: "uppercase",
        textAlign: "center",
    },
    loginRightWrapper: {
        position: "relative",
        "& .loginrightwithbg": {
            "& .wrapperborder": {
                border: "1px solid #FFBB00",
                position: "absolute",
                left: 0,
                width: "83%",
                height: "100%",
            },
            padding: "26px 62px 22px 44px",
            marginLeft: 28,
            background: theme.palette.background.formWrappers,
            "& img": {
                width: "100%",
            },
            "& p": {
                fontSize: 20,
                marginBottom: 0,
                maxWidth: "80%"
            },
            "& h1": {
                textTransform: "uppercase",
            }
        }
    },
    selectLoginOpt: {
        display: "inline",
    },
    error: {
        fontSize: 13,
        color: "red",
        display: "block"
    }

})