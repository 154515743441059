import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class MainContent extends Component {
  constructor(props) {
    super(props);

    this.modules = {
			toolbar: [
		      [{ 'font': [] }],
		      [{ 'size': ['small', false, 'large', 'huge'] }],
		      ['bold', 'italic', 'underline'],
		      [{'list': 'ordered'}, {'list': 'bullet'}],
		      [{ 'align': [] }],
		      [{ 'color': [] }, { 'background': [] }],
		      ['clean'],
          ['link']
		    ]
		};

		this.formats = [
		    'font',
		    'size',
		    'bold', 'italic', 'underline',
		    'list', 'bullet',
		    'align',
		    'color', 'background', 'link'
	  	];
      
    this.state = {
      editorState: ""
    };
  }

  onChange = (newState) => {
    this.setState({
      editorState: newState,
    });
    this.props.saveEditorTextHandler(newState);
  };

  componentDidMount = async () => {
    this.setState({
      editorState: this.props.detail
    });
    let currentContentAsHTML = this.props.detail
    this.props.saveEditorTextHandler(currentContentAsHTML);
  };

  render() {
    const { classes, fields } = this.props;

    return (
      <>
        <label className={classes.labels}>
          {fields.Detail.label}
        </label>
        <div className={classes.texteditorwrapper}>
          <ReactQuill 
            theme="snow"
            modules={this.modules}
				    formats={this.formats}
            value={this.state.editorState}
            onChange={this.onChange}/>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(MainContent);
