import { connect } from "react-redux";
import UpdateCategory from "./component";
import {
    textFieldChangeHandler,
    updateCategoryFields,
    saveEditorTextHandler,
} from "../actions";
import instance, { BASE_URL } from "../../../../../api/config";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import ls from 'local-storage';

const mapStateToProps = state => ({
    fields: state.CATEGORY.forms.createCategory.fields,
})

const mapDispatchToProps = (dispatch, props) => ({
    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(textFieldChangeHandler(name, e.value));
    },
    submitFormHandler: (Title, Detail, Name, BgColor, FontColor, history) => {
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Updating Category", "small"));
        const data = {
            categoryTitle: Title,
            categoryDetail: Detail,
            categoryName: Name,
            categoryNameBgColor: BgColor,
            categoryNameFontColor: FontColor,
        }
        const id = ls.get('categoryId')
        instance.put(BASE_URL+'category/'+id, data)
        .then(res => {
            dispatch(handleLoadingSpinnerClose());
            window.location.replace('/user/category-all')
        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
    },
    updateCategoryFields: (value) => {
        dispatch(updateCategoryFields(value))
    },
    saveEditorTextHandler: (value) => {
        dispatch(saveEditorTextHandler(value))
    },
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitFormHandler: () => {
        dispatchProps.submitFormHandler(stateProps.fields.Title.value, stateProps.fields.Detail.value, stateProps.fields.Name.value, stateProps.fields.BgColor.value, stateProps.fields.FontColor.value, ownProps.history)
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(UpdateCategory);