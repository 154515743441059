import {
    CATEGORY_PAGE_TEXT_CHANGE_HANDLER,
    CATEGORY_PAGE_SELECT_CHANGE_HANDLER,
    CATEGORY_PAGE_SAVE_ALL_HANDLER,
    CATEGORY_PAGE_EDITOR_TEXT_CHANGE_HANDLER
} from "./actions";

export const categoryReducer = (state = [], action) => {
    switch (action.type) {

        case CATEGORY_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    createCategory: {
                        ...state.forms.createCategory,
                        fields: {
                            ...state.forms.createCategory.fields,
                            [action.key]: {
                                ...state.forms.createCategory.fields[action.key],
                                value: action.payload
                            }
                        }
                    }
                }
            };
        }

        case CATEGORY_PAGE_SELECT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    createCategory: {
                        ...state.forms.createCategory,
                        fields: {
                            ...state.forms.createCategory.fields,
                            [action.key]: {
                                ...state.forms.createCategory.fields[action.key],
                                value: (action.payload === null) ? [] : [action.payload]
                            }
                        }
                    }
                }
            }
        }

        case CATEGORY_PAGE_SAVE_ALL_HANDLER: {
            return {
                ...state,
                data: {
                    ...state.data,
                    categories: [...action.payload]
                }
            }
        }

        case CATEGORY_PAGE_EDITOR_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    createCategory: {
                        ...state.forms.createCategory,
                        fields: {
                            ...state.forms.createCategory.fields,
                            Detail: {
                                ...state.forms.createCategory.fields.Detail,
                                value: action.payload
                            }
                        }
                    }
                }
            };
        }
        
        default: {
            return {
                ...state
            };
        }
    }
}