import {connect} from "react-redux";
import Header from "./component";
import {reverseString} from "../../../../common/Helpers";
import ls from 'local-storage';
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import instance, { BASE_URL } from "../../../../../api/config";

const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch, props) => ({
    logOut: (historyObject) => {
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Signing Out...", "large"));
        instance.post(BASE_URL+ 'users/logout')
        .then(res => {
            localStorage.removeItem(reverseString("app_store"));
            ls.clear();
            window.location.replace("/login");
            dispatch(handleLoadingSpinnerClose());
        })
        .catch(err => {
            dispatch(handleLoadingSpinnerClose());
        })
        
    },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Header);