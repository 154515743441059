import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit"
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import { NavLink } from 'react-router-dom';

class AllTemplates extends Component {

    state = {
        show: false,
        showDelete: false,
        currentId: "",
        anchorEl: null,
        editData:'',
        showEdit:false
    }

    componentDidMount = () => {
        this.props.fetchDataIfNeeded();
    }

    editHandler = (id) => {
        this.props.updateTemplateHandler(id, this.props.history)
    }

    showAlertDialogDelete = (value, id) => {
        this.setState({
            showDelete: value,
            currentId: id
        })
    }

    deleteApplicationHandler = () => {
        this.setState({
            showDelete: false
        })
        
        this.props.deleteApplicationHandler(this.state.currentId)
    }

    render() {
        const { 
            classes,
            tableData
        } = this.props;

        const columns = ["Title", "Title BG Color", "Title Font Color", "Allow Button Color", "Cancel Button Color",
        {
            name: "Content Details",
            label: "Content Details",
            options: {
                customBodyRender: (props, data) => {

                    return (
                        <p className={classes.tabledescription}>{props}</p>
                    )
                },
            }
        },
        {
            name: "Main Contennt",
            label: "Main Contennt",
            options: {
                customBodyRender: (props, data) => {

                    return (
                        <div dangerouslySetInnerHTML={{ __html: props }} />
                    )
                },
            }
        },
            {
                name: "Action",
                label: "Action",
                options: {
                    customBodyRender: (props, data) => {
                        let rowDataValue = data.rowData[7]

                        return (
                            <div>
                                <NavLink
                                    to={'/download'}
                                >
                                    Download
                                </NavLink>
                                <Button className={classes.tableicons} color="primary" variant="outlined" onClick={()=>this.editHandler(rowDataValue)}><EditIcon /></Button>
                                <Button className={classes.tableicons} color="primary" variant="outlined" onClick={() => this.showAlertDialogDelete(true, rowDataValue)}><DeleteIcon /></Button>
                            </div>
                        )
                    },
                }
            }
        ];

        const options = {
            search: false,
            download: false,
            print: false,
            viewColumns: false,
            filter: false,
            checkbox: false,
            selectableRowsHeader: false,
            selectableRows: false
        }

        return (
            <>
            <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>View all Templates</p>
                    <MUIDataTable 
                        title={"Templates"} 
                        data={tableData} 
                        columns={columns}
                        options={options}
                    />
                </div>
            </div>
            {
                this.state.showDelete &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showDelete}
                    showTitle={true}
                    title={"Delete Template"}
                    message={"Are you sure want to delete this template?"}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showAlertDialogDelete(false)}
                    closeAlertDialog={() => this.showAlertDialogDelete(false)}
                    applyAlertDialog={() => this.deleteApplicationHandler()}
                />
            }
            </>
        )
    }
}

export default withStyles(styles)(AllTemplates);