import {loginReducer} from "../components/Login/loginReducer";
import {confirmationDialogReducer} from "../components/common/ReduxBased/confirmationDialog/confirmationDialogReducer";
import {loadingSpinnerReducer} from "../components/common/ReduxBased/LoadingSpinner/loadingSpinnerReducer";
import {userDashboardReducer} from "../components/ITool/Dashboard/userDashboardReducer";
import {dashboardLandingReducer} from "../components/ITool/Dashboard/Landing/dashboardLandingReducer";
import {applicationReducer} from "../components/ITool/Dashboard/Application/applicationReducer";
import {templateReducer} from "../components/ITool/Dashboard/Template/templateReducer";
import {categoryReducer} from "../components/ITool/Dashboard/Category/categoryReducer";
import {consentReducer} from "../components/ITool/Dashboard/Consent/consentReducer";

import {combineReducers} from "redux";
import reduceReducers from "reduce-reducers";

const rootReducer = reduceReducers(
    combineReducers({
        LOGIN: loginReducer,
        ALERT_DIALOG: confirmationDialogReducer,
        LOADING_SPINNER: loadingSpinnerReducer,
        DASHBOARD: userDashboardReducer,
        LANDING_DASHBOARD: dashboardLandingReducer,
        APPLICATION: applicationReducer,
        TEMPLATE: templateReducer,
        CATEGORY: categoryReducer,
        CONSENT: consentReducer
    }),
);

export default rootReducer;
