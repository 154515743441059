import {
    TEMPLATE_PAGE_TEXT_CHANGE_HANDLER,
    TEMPLATE_PAGE_SELECT_CHANGE_HANDLER,
    TEMPLATE_PAGE_SAVE_ALL_HANDLER,
    TEMPLATE_PAGE_SAVE_APPLICATIONS_HANDLER,
    TEMPLATE_PAGE_SAVE_CATEGORIES_HANDLER,
    TEMPLATE_PAGE_MULTI_SELECT_CHANGE_HANDLER,
    TEMPLATE_PAGE_EDITOR_CHANGE_HANDLER,
    TEMPLATE_PAGE_APPLICATION_CHANGE_HANDLER,
    TEMPLATE_PAGE_CATEGORIES_CHANGE_HANDLER,
    TEMPLATE_PAGE_CATEGORIES_CHANGE_UPDATE_HANDLER,
    TEMPLATE_PAGE_CONTENT_EDITOR_CHANGE_HANDLER
} from "./actions";

export const templateReducer = (state = [], action) => {
    switch (action.type) {

        case TEMPLATE_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    createTemplate: {
                        ...state.forms.createTemplate,
                        fields: {
                            ...state.forms.createTemplate.fields,
                            [action.key]: {
                                ...state.forms.createTemplate.fields[action.key],
                                value: action.payload
                            }
                        }
                    }
                }
            };
        }

        case TEMPLATE_PAGE_SELECT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    createTemplate: {
                        ...state.forms.createTemplate,
                        fields: {
                            ...state.forms.createTemplate.fields,
                            [action.key]: {
                                ...state.forms.createTemplate.fields[action.key],
                                value: (action.payload === null) ? [] : [action.payload]
                            }
                        }
                    }
                }
            }
        }

        case TEMPLATE_PAGE_SAVE_APPLICATIONS_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    createTemplate: {
                        ...state.forms.createTemplate,
                        fields: {
                            ...state.forms.createTemplate.fields,
                            Application: {
                                ...state.forms.createTemplate.fields.Application,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }

        case TEMPLATE_PAGE_SAVE_CATEGORIES_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    createTemplate: {
                        ...state.forms.createTemplate,
                        fields: {
                            ...state.forms.createTemplate.fields,
                            Categories: {
                                ...state.forms.createTemplate.fields.Categories,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }

        case TEMPLATE_PAGE_MULTI_SELECT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    createTemplate: {
                        ...state.forms.createTemplate,
                        fields: {
                            ...state.forms.createTemplate.fields,
                            [action.key]: {
                                ...state.forms.createTemplate.fields[action.key],
                                value: (action.payload === null) ? [] : action.payload.map((item, index) => {
                                    item.index = index;
                                    return item;
                                })
                            }
                        }
                    }
                }
            }
        }

        case TEMPLATE_PAGE_SAVE_ALL_HANDLER: {
            return {
                ...state,
                data: {
                    ...state.data,
                    templates: [...action.payload]
                }
            }
        }

        case TEMPLATE_PAGE_EDITOR_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    createTemplate: {
                        ...state.forms.createTemplate,
                        fields: {
                            ...state.forms.createTemplate.fields,
                            PopupDiscription: {
                                ...state.forms.createTemplate.fields.PopupDiscription,
                                value: action.payload
                            }
                        }
                    }
                }
            };
        }

        case TEMPLATE_PAGE_CONTENT_EDITOR_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    createTemplate: {
                        ...state.forms.createTemplate,
                        fields: {
                            ...state.forms.createTemplate.fields,
                            MainContent: {
                                ...state.forms.createTemplate.fields.MainContent,
                                value: action.payload
                            }
                        }
                    }
                }
            };
        }

        case TEMPLATE_PAGE_APPLICATION_CHANGE_HANDLER: {
            const value = action.payload
            const options = state.forms.createTemplate.fields.Application.options

            const updatedValue = options?.find(item => {
                if(item.link === value) {
                    return item
                }
            })
            return {
                ...state,
                forms: {
                    ...state.forms,
                    createTemplate: {
                        ...state.forms.createTemplate,
                        fields: {
                            ...state.forms.createTemplate.fields,
                            [action.key]: {
                                ...state.forms.createTemplate.fields[action.key],
                                value: (updatedValue === null) ? [] : [updatedValue]
                            }
                        }
                    }
                }
            }
        }

        case TEMPLATE_PAGE_CATEGORIES_CHANGE_HANDLER: {
            const value = action.payload

            const updatedValue = value.map(item => {
                return {
                    value: item._links.category.href,
                    label: item.categoryName
                }
            })
            
            return {
                ...state,
                forms: {
                    ...state.forms,
                    createTemplate: {
                        ...state.forms.createTemplate,
                        fields: {
                            ...state.forms.createTemplate.fields,
                            [action.key]: {
                                ...state.forms.createTemplate.fields[action.key],
                                value: (updatedValue === null) ? [] : [...updatedValue]
                            }
                        }
                    }
                }
            }
        }

        case TEMPLATE_PAGE_CATEGORIES_CHANGE_UPDATE_HANDLER: {
            return {
                ...state,
                UISettings: {
                    ...state.UISettings,
                    isUpdate: action.payload
                }
            }
        }
        
        default: {
            return {
                ...state
            };
        }
    }
}