export const styles = theme => ({
    logintitle: {
        marginTop: 0,
        marginBottom: 0,
        color: theme.palette.primary.main,
        fontSize: 48,
    },
    loginsubtitle: {
        marginBottom: 7,
    },
    fields: {
        marginBottom: 13,
        padding: 13,
    },
    colorFields: {
        marginBottom: 13,
        padding: 13,
        "& input": {
            height: "100% !important"
        }
    },
    loginheaderlogo: {
        maxWidth: 200,
        marginBottom: 30,
    },
    applicationWrapper: {
        padding: "32px",
    },
    polygonbg: {
        backgroundSize: "cover",
        minHeight: "100vh",

    },
    submitBtn: {
        display: "block",
        width: "200px",
        borderRadius: "5px !important",
        marginTop: 10,
        marginLeft: 13,
    },
    labels: {
        paddingLeft: 13,
    },
    cookiecontent: {
        position: "fixed",
        width: "100%",
        bottom: 0,
        left: 0,
        right: 0,
        "& .cookiewrapper": {
            background: "#3665F3",
            padding: "22px 48px",
          },
        "& .cookieoptionbtns": {
            display: "flex",
            justifyContent: "flex-end",
            "& button": {
                background: "none",
                border: "none",
              },
            "& .first": {
                border: "1px solid white",
                fontSize: 15,
                width: 156,
                padding: 6,
              },
            "& .second": {
                border: "1px solid white",
                background: "white",
                color: "#3665F3",
                fontSize: 15,
                width: 156,
                padding: 6,
                marginLeft: 17,
              }
          },
    },
    showcookie: {
        margin: 13,
        marginLeft: 48
    },
    popuptitle: {
        display: "block",
        width: "100%",
        borderBottom: "1px solid blue",
    },
    texteditorwrapper: {
        width: "98%",
        "& .quill": {
            minHeight: 213,
            "& .ql-container": {
                minHeight: 213,
            },
            "& .ql-tooltip.ql-editing": {
                left: "0px !important",
            }
        }
    },
})