import { connect } from "react-redux";
import AllTemplates from "./component";
import {
    saveEditorTextHandler,
} from "../../actions";
const mapStateToProps = state => ({
    fields: state.TEMPLATE.forms.createTemplate.fields,
})

const mapDispatchToProps = (dispatch, props) => ({
    saveEditorTextHandler: (value) => {
        dispatch(saveEditorTextHandler(value))
    },
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(AllTemplates);