export const styles = theme => ({
    mainrapper: {
        minHeight: "calc(100vh - 116px)",
        padding: 32,
    },
    tableicons: {
        borderColor: "transparent",
    },
    tabledescription: {
        maxHeight: 38,
        overflow: "hidden",
    }
})